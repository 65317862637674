import $ from "jquery";
import { gsap } from "gsap";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/effect-fade";
import "swiper/scss/free-mode";
import "swiper/scss/thumbs";
import "swiper/scss/mousewheel";

const { default: Swiper } = await import("swiper");
const { Navigation, Pagination, EffectFade, FreeMode, Thumbs, Mousewheel } = await import(
  "swiper/modules"
);

const initializeThumbsSlider = () => {
  return new Swiper(".product__photos .js-slider-thumbs", {
    modules: [FreeMode, Navigation],
    slidesPerView: 7,
    spaceBetween: 8,
    direction: "vertical",
    freeMode: true,
    watchSlidesProgress: true,
    navigation: {
      nextEl: ".swiper-btn-next",
      prevEl: ".swiper-btn-prev",
    },
  });
};

const initializeMainSlider = (sliderThumbs) => {
  $(".product__photos .js-slider-main").each(function () {
    const swiperOptions = {
      modules: [Pagination].concat(sliderThumbs ? [Thumbs, Mousewheel] : [EffectFade]),
      direction: sliderThumbs ? "vertical" : "horizontal",
      mousewheel: !!sliderThumbs,
      slidesPerView: 1,
      speed: sliderThumbs ? 1000 : "",
      thumbs: {
        swiper: sliderThumbs || null,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        type: sliderThumbs ? "progressbar" : "bullets",
      },
    };

    if (!sliderThumbs) {
      swiperOptions.effect = "fade";
      swiperOptions.fadeEffect = {
        crossFade: true,
      };
    }

    $(this).data("swiperInstance", new Swiper(this, swiperOptions));
  });
};

let sliderThumbs;

const destroySliders = () => {
  $(".product__photos .js-slider-thumbs, .product__photos .js-slider-main").each(function () {
    const swiperInstance = $(this).data("swiperInstance");
    if (swiperInstance) swiperInstance.destroy(true, true);
  });
  sliderThumbs = null;
};

const mm = gsap.matchMedia();

mm.add("(min-width: 768px)", () => {
  sliderThumbs = initializeThumbsSlider();
  initializeMainSlider(sliderThumbs);

  return () => {
    destroySliders();
  };
});

mm.add("(max-width: 767px)", () => {
  initializeMainSlider(null);

  return () => {
    destroySliders();
  };
});
